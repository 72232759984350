













































































































































































import Bop from "@/models/Bop";
import MinimumFacturable from "@/models/MinimumFacturable";
import MinimumFacturableService from "@/services/MinimumFacturableService";
import { BopModelEnum } from "@/utils/Enums";
import { roundNumber } from "@/utils/helpers";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ComplementsCoutsSuppAdministrationService from "@/services/ComplementsCoutsSuppAdministrationService";
import CountryService from "@/services/CountryService";
type ComplementaryDataSummary = {
  key: string,
  value: string,
  description: string,
  currencySymbol: string
}
@Component({})
export default class BopSummaryStep extends Vue {
  private minimumFacturable: number = 0;
  private complementaryData: Array<ComplementaryDataSummary> = [];
  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }

   private get bopShort(): String {
    return `${this.bop.siteName} - ${this.bop.bopModelName?.toString().toLowerCase()} ${this.bop.bopNumber ? " # ":""}${this.bop.bopNumber ?? ""}`;
  }

  private get bop(): Bop {
    return this.$store.state.bop;
  }
  private get bopStatus() {
    return this.$store.state.bopStatuses[this.bop.bopStatusKey];
  }
  private get bopTotalOperationPrincipalDiversePoints() {
    return roundNumber(
      this.bop.totalOperationDiversePoints +
        this.bop.totalOperationPrincipalPoints
    );
  }
  private get bopComplementaryData(){
    return this.$store.state.bop.complementaryData;
  }
  private get bopTotalPoints() {
    let total = this.bop.totalPoints;
    if (
      this.canBeEditedAfterRealised ||
      !Bop.readOnlyStatuses.includes(this.bop.bopStatusKey)
    ) {
      const totalOpprincipaleDiverse =
        this.bopTotalOperationPrincipalDiversePoints > this.minimumFacturable
          ? this.bopTotalOperationPrincipalDiversePoints
          : this.minimumFacturable;
      total = totalOpprincipaleDiverse + this.totalOperationSuppPoints;
    }
    return roundNumber(total);
  }
  private get totalOperationSuppPoints(): number {
    const total =
      (this.bop.totalSuppHauteurPoints ?? 0) +
      this.bop.totalOperationSuppHeurePoints +
      this.bop.totalOperationSuppEpiPoints;
    return roundNumber(total);
  }
  private get isElectriciteInstrumentationBop() {
    return this.bop.bopModelKey === BopModelEnum.ElectriciteInstrumentation;
  }
  private get isTuyauterieMetaliqueBop() {
    return this.bop.bopModelKey === BopModelEnum.TuyauterieMetalique;
  }
  private  get language():string{
    return this.$i18n.locale;
  }
  private async getTuyPlastiqueSupplements(): Promise<Array<ComplementaryDataSummary>>{
    // console.log("in getTuyPlastiqueSupplements ");
    // console.log(this.isTuyauterieMetaliqueBop);
    if(!this.isTuyauterieMetaliqueBop) return [];
    const allComplementsCoutsSuppConfigs = (await ComplementsCoutsSuppAdministrationService.getComplementsCoutsSuppAdministration(this.language)).data;
    // console.log(allComplementsCoutsSuppConfigs);
    const filteredConfig =  allComplementsCoutsSuppConfigs.find(c=> c.typeTravauxId === this.bop.typeTravaux)?.complementsCoutsSuppKeys;
    let complementaryData = [];
    const country = this.bop.siteId ? (await CountryService.getCountryBySite(this.bop.siteId)).data : null;

    // console.log(filteredConfig);
    if(filteredConfig) {
      complementaryData = filteredConfig.map(key =>({
        key,
        value: this.bop.complementaryData ?  this.bop.complementaryData[key] : "",
        description: this.bop.complementaryData ?  this.bop.complementaryData[`${key}Description`] : "",
        currencySymbol : country?.currencySymbol
      }));
    }

    return  complementaryData;
  }
  /**
   * ######
   * Hooks
   * ######
   */
  private created() {
    if (!this.bop.bopModelId || !this.bop.entrepriseId || !this.bop.typeTravaux)
      return;
    this.getTuyPlastiqueSupplements().then(complementaryData => {
      this.complementaryData = complementaryData;
      // console.log(this.complementaryData);
    })

    MinimumFacturableService.getByEntrepriseBopModelDomainApplication(
      this.bop.bopModelId,
      this.bop.entrepriseId,
      this.bop.typeTravaux
    ).then((response) => {
      const minimFacturable: MinimumFacturable = response.data;
      this.minimumFacturable = minimFacturable.minimumPoints;
    });
  }
  /**
   * ######
   * Watchers
   * ######
   */

  @Watch("bop.bopModelId")
  onBopModelChange(){
    this.getTuyPlastiqueSupplements().then(complementaryData => {
      this.complementaryData = complementaryData;
      // console.log(this.complementaryData);
    });
  }
  @Watch("bopComplementaryData", {deep:true})
  private onComplementaryDataChange(){
    // console.log("im in change");
    // console.log(this.complementaryData);
    this.complementaryData = this.complementaryData.map((d:ComplementaryDataSummary)=>
      ({...d,value: this.bop.complementaryData ?  this.bop.complementaryData[d.key] : ""})
    );
  }
}
